import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data('video_player', (uid, jwoptions) => ({
  init () {
    const player_element = document.getElementById(`video_player_${uid}`);
    let player = jwplayer(player_element).setup(jwoptions);
    // The reels script (js/alpine/ReelsPlayer) defines a callback
    // function stored under the UID in window.frop_players to be
    // run here once the JWPlayer has initialised.
    if (!window.frop_players) window.frop_players = [];
    const cb = window.frop_players[uid];
    window.frop_players[uid] = player;
    if (typeof cb === 'function') cb();
  }
}));
